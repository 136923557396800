<template>
  <section class="index-products">
    <div class="index-products__container container">
      <div class="index-products__info">
        <h2 v-t="'index.products.title'" />
        <p v-t="'index.products.text'" />
        <a target="_blank" href="https://app.roobee.io/sign_up" class="start">
          <span>{{ $t('index.products.start') }} ${{ investingPrice }}</span>
        </a>
        <div class="after-registration">
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M17.334 6.667h7.333c.55 0 .863.627.533 1.066l-3.7 4.934 3.7 4.933a.667.667 0 01-.533 1.067H18a2 2 0 01-2-2 .667.667 0 00-.666-.667H8v11.333a.667.667 0 11-1.333 0V5.99a2 2 0 012-2h6.667a2 2 0 012 2v.677zm0 1.333v2A.667.667 0 0116 10V7.336v-.003-1.342a.667.667 0 00-.666-.667H8.667A.667.667 0 008 5.99v8.677h7.334a2 2 0 012 2c0 .368.298.666.666.666h5.334l-3.2-4.266a.667.667 0 010-.8L23.334 8h-6z" fill="#716E6E" stroke="#716E6E" stroke-width=".4"/></svg>
          <span v-t="'index.products.after-registration'" />
        </div>
      </div>
      <div class="index-products__slider">
        <div v-swiper:mySwiper="swiperOption">
          <div class="swiper-wrapper">
            <div class="swiper-slide index-products__slide" :key="i" v-for="(product, i) in products">
              <div class="pic">
                <img :src="`/images/products/${product.slug}.png`" :alt="product.slug">
              </div>
              <h3 class="title" v-t="`index.products.titles.${product.slug}`" />
              <span class="company">{{ typeof product.company === 'string' ? product.company : product.company[locale.toLowerCase()] }}</span>
              <div class="info">
                <div class="info__item" v-for="(prop, k) in product.props" :key="`prop-${i}-${k}`">
                  <h4 class="title">{{ $t(`index.products.props.${prop.title}`) }}</h4>
                  <div v-if="prop.title === 'd-down'" class="value" :class="{plus: typeof prop.value === 'string' && prop.value.startsWith('+'), minus: typeof prop.value === 'string' && prop.value.startsWith('-')}">
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="typeof prop.value === 'string' && prop.value" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11.362 8.497H4.833a.333.333 0 010-.667h6.529L9.264 5.733a.333.333 0 11.472-.472l2.666 2.667c.13.13.13.341 0 .472l-2.666 2.666a.333.333 0 11-.472-.471l2.098-2.098z" fill="#6F7270" stroke="#6F7270" stroke-width=".5"/></svg>
                    <span>{{ prop.value !== 'TBD' ? prop.value.slice(1) : prop.value }}</span>
                  </div>
                  <span v-else class="value no-bg">{{ typeof prop.value === 'string' ? prop.value : prop.value[locale.toLowerCase()] }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "Products",

  props: ['investingPrice', 'products'],

  data() {
    return {
      swiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 16,
        loop: true,
        speed: 800,
        autoplay: {
          duration: 3000,
          disableOnInteraction: false
        },
        slidesOffsetAfter: 2,
        slidesOffsetBefore: 2,

        breakpoints: {
          601: {
            spaceBetween: 32
          }
        }
      }
    }
  },

  computed: {
    ...mapState(['locale']),
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/elements/index/products';
</style>